{
  "name": "@vladmandic/human",
  "version": "3.3.2",
  "description": "Human: AI-powered 3D Face Detection & Rotation Tracking, Face Description & Recognition, Body Pose Tracking, 3D Hand & Finger Tracking, Iris Analysis, Age & Gender & Emotion Prediction, Gesture Recognition",
  "sideEffects": false,
  "main": "dist/human.node.js",
  "module": "dist/human.esm.js",
  "browser": "dist/human.esm.js",
  "types": "types/human.d.ts",
  "exports": {
    "node": "./dist/human.node.js",
    "script": "./dist/human.js",
    "module": "./dist/human.esm.js",
    "types": "./types/human.d.ts",
    "dist/human": "./dist/human.js",
    "dist/human.js": "./dist/human.js",
    "dist/human.esm": "./dist/human.esm.js",
    "dist/human.esm.js": "./dist/human.esm.js",
    "dist/human.esm-nobundle": "./dist/human.esm-nobundle.js",
    "dist/human.esm-nobundle.js": "./dist/human.esm-nobundle.js",
    "dist/human.node": "./dist/human.node.js",
    "dist/human.node.js": "./dist/human.node.js",
    "dist/human.node-wasm": "./dist/human.node-wasm.js",
    "dist/human.node-wasm.js": "./dist/human.node-wasm.js",
    "dist/human.node-gpu": "./dist/human.node-gpu.js",
    "dist/human.node-gpu.js": "./dist/human.node-gpu.js",
    "require": "./dist/human.node.js",
    "import": "./dist/human.esm.js"
  },
  "author": "Vladimir Mandic <mandic00@live.com>",
  "bugs": {
    "url": "https://github.com/vladmandic/human/issues"
  },
  "homepage": "https://vladmandic.github.io/human/demo/index.html",
  "license": "MIT",
  "engines": {
    "node": ">=14.0.0"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/vladmandic/human.git"
  },
  "scripts": {
    "start": "node --no-warnings demo/nodejs/node.js",
    "dev": "build --profile development",
    "clean": "build --profile clean",
    "build": "rimraf test/build.log && node build.js",
    "test": "node --no-warnings --unhandled-rejections=strict --trace-uncaught test/node.js",
    "lint": "eslint *.json *.js src demo test models wiki",
    "scan": "npx auditjs@latest ossi --dev --quiet"
  },
  "keywords": [
    "human",
    "human-library",
    "face-detection",
    "faceid",
    "face-geometry",
    "face-embedding",
    "face-recognition",
    "face-description",
    "face-matching",
    "body-tracking",
    "body-segmentation",
    "hand-tracking",
    "iris-tracking",
    "age-estimation",
    "emotion-detection",
    "gender-prediction",
    "gesture-recognition",
    "gaze-tracking",
    "age-gender",
    "tensorflowjs",
    "tfjs",
    "tensorflow"
  ],
  "devDependencies": {
    "@html-eslint/eslint-plugin": "^0.26.0",
    "@html-eslint/parser": "^0.26.0",
    "@microsoft/api-extractor": "^7.47.7",
    "@tensorflow/tfjs-backend-cpu": "^4.21.0",
    "@tensorflow/tfjs-backend-wasm": "^4.21.0",
    "@tensorflow/tfjs-backend-webgl": "^4.21.0",
    "@tensorflow/tfjs-backend-webgpu": "4.21.0",
    "@tensorflow/tfjs-converter": "^4.21.0",
    "@tensorflow/tfjs-core": "^4.21.0",
    "@tensorflow/tfjs-data": "^4.21.0",
    "@tensorflow/tfjs-layers": "^4.21.0",
    "@tensorflow/tfjs-node": "^4.21.0",
    "@tensorflow/tfjs-node-gpu": "^4.21.0",
    "@types/emscripten": "^1.39.13",
    "@types/node": "^22.5.4",
    "@types/offscreencanvas": "^2019.7.3",
    "@typescript-eslint/eslint-plugin": "^8.5.0",
    "@typescript-eslint/parser": "^8.5.0",
    "@vladmandic/build": "^0.10.1",
    "@vladmandic/pilogger": "^0.5.1",
    "@vladmandic/tfjs": "github:vladmandic/tfjs",
    "canvas": "^2.11.2",
    "esbuild": "^0.23.1",
    "eslint": "8.57.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-plugin-html": "^8.1.1",
    "eslint-plugin-import": "^2.30.0",
    "eslint-plugin-json": "^4.0.1",
    "eslint-plugin-markdown": "^5.1.0",
    "eslint-plugin-node": "^11.1.0",
    "eslint-plugin-promise": "^7.1.0",
    "rimraf": "^6.0.1",
    "tslib": "^2.7.0",
    "typedoc": "0.26.7",
    "typescript": "5.5.4"
  }
}
